// export const BASE_URL = "http://192.168.85.100:7001";
// export const BASE_URL = "http://hilife.ai";
// export const BASE_URL = "http://hihrms.hilifeai.in";
// export const BASE_URL = "http://anubhuthi.org";
export const BASE_URL = "https://hilifeai.in";
// export const BASE_URL = "http://hrms.ttsbs.com";
// export const BASE_URL = "http://hrms.ttsbs.in";
// export const BASE_URL = "http://hihrms.ttsbusinessservices.com";
export const AUTH = {
  LOGIN: `${BASE_URL}/api/authlog`,
  LOGINCHECK: `${BASE_URL}/api/authlogcheck`,
  GETUSERINDIVIDUAL: `${BASE_URL}/api/userindividual`,
  GETUSERATTINV: `${BASE_URL}/api/userattindv`,
  GETUSER: `${BASE_URL}/api/auth`,
  GETAUTHROLE: `${BASE_URL}/api/authroles`,
  LOGOUT: `${BASE_URL}/api/authout`,
  PROJECTLIMIT: `${BASE_URL}/api/projectslimit`,
  TASKSLIMIT: `${BASE_URL}/api/allfiltertask`,
  ALLTASKS: `${BASE_URL}/api/alltasktime`,
  ALLUSERLIMIT: `${BASE_URL}/api/usersalllimit`,
  VERIFYTWOFA: `${BASE_URL}/api/verifytwofa`,
  VERIFYTWOFACHECK: `${BASE_URL}/api/verifytwofacheck`,
  VERIFYVIEWPASSWORD: `${BASE_URL}/api/verification/viewpassword`,
  GETDOCUMENTS:`${BASE_URL}/api/employeedocumentcommonid`,
};
