import Box from "@mui/material/Box";
import React from "react";
import LoadingSVG from './images/loadingimage.svg';
import './loading.css';

const Loading = () => {
  return (
    <Box
      className="loader-back"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",

      }}
    >
      <div style={{
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
      }}>
        <img src={LoadingSVG} alt="loading" width={120} height={100} />
        <span class="loader1 gradient-text">L &nbsp; ading</span>
      </div>

    </Box>
  );
};

export default Loading;