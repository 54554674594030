import { BrowserRouter, Routes, Route } from "react-router-dom";
import JobRolesWebsite from "../webpages/career";
import JobdescriptionWebsite from "../webpages/jobdescription";
import Candidatewebsite from "../webpages/candidate";
import Response from "../webpages/response";
import InterviewEndPageRetest from "../webpages/InterviewEndPageRetest";
import InterFomrGenerate from "../webpages/interFormGenerate";
import InterviewFormHr from "../webpages/InterviewFormHr";
import InterviewEndPage from "../webpages/InterviewEndPage";
import InterviewTestRound from "../webpages/InterviewTestRound";
import DocumentPreparationpage from "../webpages/DocumentPreparationpage";
import CandidateLinkUpload from "../webpages/CandidateLinkUpload";
import UploadThankYou from "../webpages/UploadThankYou";
import PasswordResetGreetMsg from "../webpages/PasswordResetGreetMsg";
import UserPasswordCredentials from "../webpages/userPasswordCredentials";

function Webstock() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/career" element={<JobRolesWebsite />} />
          <Route
            path="/career/jobdescriptions/:jobname/:id"
            element={<JobdescriptionWebsite />}
          />
          <Route path="/career/candidate/:id" element={<Candidatewebsite />} />
          <Route path="/career/response" element={<Response />} />
          <Route
            path="/hr/:from/:username/:password/:roundid/:id/:candidateid"
            element={<InterviewFormHr />}
          />
          <Route
            path="/interview/interviewformgenerate/:mode/:testcount/:candidateid/:roundid/:autofill/:id"
            element={<InterFomrGenerate />}
          />

          <Route
            path="interview/interviewendpage/:mode/:candidateid/:roundid"
            element={<InterviewEndPage />}
          />
          <Route
            path="interview/interviewendpage/typingtest/:testcount/:candidateid/:roundid/:questionid"
            element={<InterviewEndPageRetest />}
          />
          <Route
            path="/interview/interviewtestround/:candidateid/:roundid/:autofill/:idgen"
            element={<InterviewTestRound />}
          />
           <Route
            path="/usercredentials/reset/:id"
            element={<UserPasswordCredentials />}
          />
           <Route path="/passwordresetgreetmsg" element={<PasswordResetGreetMsg />} />
           <Route
            path="/document/documentpreparation/:name/:id/:issuedperson/:date/:userid"
            element={<DocumentPreparationpage />}
          />
          <Route
            path="/uploaddocument/:count/:filename/:uniqueid"
            element={<CandidateLinkUpload />}
          />
          <Route path="/thankyouforupload" element={<UploadThankYou />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Webstock;